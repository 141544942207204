@import "src/index.scss";

header {
    display: flex;
    text-align: left;
    > * {
        display: inline-block;
    }
    #logo {
        height: 100px;
        margin: 0 auto;
        text-align: center;
        width: 125px;
        
        img {
            max-height: 100%;
            max-width: 100%;
        }
    }
    nav {
        text-align: right;
        width: 100%;

        ul {
            margin: 0;

            li {
                display: inline-block;
                list-style: none;
                padding: 1em;

                a {
                    color: $white;

                    &.selected,
                    &:hover {
                        color: $green;
                    }
                    
                }
                &.box {
                    border-bottom: 1px solid $white;
                    border-left: 1px solid $white;
                }

            }
        }
    }
    @media (max-width: ($breakpoint-1)) {
        text-align: center;

        > * {
            display: block;
        }            
    }
}

