@import "src/index.scss";

main {
    background-size: cover;
    margin: 0px auto;
    min-height: 60vh;
    padding: 1em;

    section {
        display: block;
        margin: 0 auto;
        max-width: $breakpoint-1;
        width: auto;

        div.grid {
            display: grid;
            grid-gap: 8px;
            grid-auto-rows: minmax(60px, auto);
            grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
            grid-auto-flow: dense;

            @media (max-width: ($breakpoint-1)) {
                grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
            }
        }

        &.large {
            max-width: $breakpoint-2;
        }
    }

    &.private {
        background: url(/static/images/acl.jpg) no-repeat top center $black;
    }
    &.public {
        background: url(/static/images/avatar.jpg) no-repeat center center $black;
    }

    .download {
        border-bottom: 1px solid $white;
        padding: 1em 0;

        h3 {
             margin: .25em;
        }
    }
}