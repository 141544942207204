@import url('https://fonts.googleapis.com/css?family=Inconsolata&display=swap');
@import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');

/* breakpoints */
$breakpoint-1: 512px;
$breakpoint-2: 1024px;

/* colorset */
$black: #000000;
$green: #50D926;
$green-light: #9cf97c;
$red: #F00;
$white: #FFF;

/* fonts */
$font-stack: 'Inconsolata', sans-serif;

/* pseudo */
::selection {
    background-color: $red;
    color: $white;
}

/* mixins */
@mixin text-overlay {
    background: rgba(0, 0, 0, 0.75);
    display: block;
    padding: .5em;
    width: auto;
}

/* tags */
a {
    color: $green;
    text-decoration: none;

    &:hover {
        color: $green-light;
        text-decoration: underline
    }
}

blockquote {
    border-left: 3px solid $red;
    padding-left: .5em; 
    text-align: left;
}

body {
    background: $black;
    color: $white;
    font: 100% $font-stack;
    letter-spacing: .05em;
    margin: 0;
    padding: 0;
    text-align: center;
}

cite {
    background: $green;
    color: $white;
    font-style: normal;
    font-weight: bold;
    margin: 0;
    padding: .2rem;
}

form {
    @include text-overlay;
    padding: 1em;

    fieldset {
        border: none;

        button,
        input[type="email"],
        input[type="password"],
        input[type="text"] {
            background: transparent;
            border: 1px solid $white;
            color: $white;
            font-size: 1.2rem;
            padding: .5em;
            width: 80%;
        }

        button {
            cursor: pointer;
            &.ko {
                color: $red;
            }
            &.ok {
                color: $green;
            }
            &:hover {
                color: $white;
            }
        }

        input[type='checkbox'],
        input[type='radio'] {
            display: none;
          
            &~label {
              vertical-align: middle;
            }
          
            &~label:hover {
                cursor: pointer;
            }

            &~label>span::before {
              background: $white;
              border: 2px solid $green;
              color: transparent;
              content: '\2713';
              display: inline-block;
              height: 1.2em;
              line-height: 1.2em;
              margin: .5em;
              text-align: center;
              padding: 0;
              width: 1.2em;
            }
          
            &:checked~label>span::before {
              background: $green;
              border-color: transparent;
              color: $black;
            }
          
          }
          
        label {
            display: block;
            font-size: .8em;
            margin: 0 auto;
            text-align: center;
            width: 75%;
            &.left {
                font-size: .7em;
                text-align: left;
                width: 100%;
            }
        }
        
        .radioOption {
            display: inline-block;

            img {
                width: 45px;
            }
        }
        
    }
}

h1 {
    @include text-overlay;
}

strong {
    color: $green;
}

table {
    background: $black;
    border: 0;
    border-collapse: collapse;
    font-size: .9em;
    margin: 1em 0;
    width: 100%;

    caption {
        margin: 1em 0;
        text-align: center;
    }

    tr {
        td, th {
            border: 1px dashed $white;
            padding: .5em .25em; 
        }
    }
}

/* generic classes */
.blackbox {
    background: $black;
    border: 4px solid $green;
    opacity: .9;
    padding: .25em;
}

.boxed {
    @include text-overlay;
    max-height: $breakpoint-1;
    overflow-x: hidden;
    overflow-y: visible;
    padding: 1em;
    .simplebar-offset {
        width: 102%;
        @media (max-width: ($breakpoint-1)) {
            width: 105%;
        }    
    }
    .simplebar-scrollbar {
        background: $green-light;
        opacity: .5;
    }
    
}

.boxed-text {
    li {
        margin: .5em 0;
    }
    li > big {
        line-height: 2em;
        text-decoration: underline;
    }
    width: 98%;
}

.error {
    color: $red;
    padding: .5em;
    text-align: center;
    white-space: pre-wrap;
}

.left {
    text-align: left;
}

.right {
    text-align: right;
}

.shake {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
}

@keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
}