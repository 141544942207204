@import 'src/index.scss';

.modal {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.25);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s;
    &:target {
      visibility: visible;
      opacity: 1;
      pointer-events: auto;
    }
    & > div {
      color: #fff;
      border: 2px solid #50D926;
      width: 75%;
      height: 100%;
      position: absolute;
      top: 0%;
      left: 50%;
      transform: translate(-50%, 0%);
      background-color: rgba(0, 0, 0, 1);
      padding: 2.5%;
      @media (max-width: ($breakpoint-1)) {
        width: 100%;
      }
      section {
          max-width: 100% !important;
          height: 100%
      }
      & .right {
        height:5%;
        margin: 0;
      }
      & .boxed {
        height: 60%;
        max-height: 60%;
      }
    }
}

.hidden {
    visibility: hidden !important;
    opacity: 0;
    pointer-events: auto;
}
.shown {
    visibility: visible !important;
    opacity: 1;
    pointer-events: auto;
}
