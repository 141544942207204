@import "src/index.scss";

footer {
    nav {
        ul {
            padding: 0;            
            li {
                list-style: none;
                display: inline;
                padding: 0 5px;
            }
        }
    }
}